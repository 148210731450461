<template>
  <div :id="id" :class="className" :style="styleObject" :chartData="chartData" />
</template>

<script>
import echarts from 'echarts';
import resize from '@/mirror-modules/resize';

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'bar-chart',
    },
    id: {
      type: String,
      default: 'bar-chart',
    },
    boxStyle: {
      type: Object,
      default: () => {
        return { width: 'auto', height: '300px' };
      },
    },
    chartData: {
      type: Object,
      default: () => {
        return {
          xData: [],
          yData: [],
        };
      },
    },
    originalData: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'dealer',
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  computed: {
    styleObject: function () {
      const obj = this.boxStyle;
      for (const attr in this.boxStyle) {
        // var current = getComputedStyle(this.boxStyle)[attr]
        // 提取单位,若存在单位，得到数组。若不存在单位，得到%
        var unit = this.boxStyle[attr].match(/[a-z]+$/);
        unit = unit ? unit[0] : '%';

        obj[attr] = unit !== '%' ? this.boxStyle[attr] : this.boxStyle[attr] + unit;
      }
      return obj;
    },
  },
  watch: {
    chartData: {
      handler() {
        this.initChart();
      },
      deep: true,
    },
  },
  mounted() {
    this.initChart();
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(document.getElementById(this.id));

      this.chart.setOption({
        title: {
          show: false,
        },
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
            const name = params[0].name;
            if (this.type === 'dealer') {
              const rowData = this.originalData.find((item) => item.memberName === name);
              return `<div>发布数 : ${rowData.totalPushCount}</div>
              <div>迟发布数 : ${rowData.latePublishedCount}</div>
              <div>未发布数 : ${rowData.unpublishCount}</div>`;
            } else {
              const rowData = this.originalData.find((item) => item.operatorName === name);
              return `<div>总计划数 : ${rowData.totalPlanCount}</div>
              <div>迟完成数 : ${rowData.latePublishedCount}</div>
              <div>未完成数 : ${rowData.unpublishCount}</div>`;
            }
          },
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: {
          top: '40px',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: this.chartData.xData,
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: this.chartData.yData,
            type: 'bar',
            barWidth: 20,
            itemStyle: {
              normal: {
                color: '#1890ff',
              },
            },
          },
        ],
      });
    },
  },
};
</script>
